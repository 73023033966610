//badge overrides
.badge{
    border-radius: 8px;
    padding: 0.5rem 1rem;

    @media screen and (max-width: 800px) {
        padding: .3em .5em;
        border-radius: 4px;
    }
}

//mmenu styles override
.mm-panels{
    width: 100%;
}