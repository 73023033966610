// List Icons
// -------------------------

.#{$fa-css-prefix}-ul {
  list-style-type: none;
  margin-left: $fa-li-width * 5/4;
  padding-left: 0;

  > li { position: relative; }
}

.#{$fa-css-prefix}-li {
  left: -$fa-li-width;
  position: absolute;
  text-align: center;
  width: $fa-li-width;
  line-height: inherit;
}


// Dash Links List
// ---

.links {
  list-style: none;
  padding: 0; margin: 0;

  .link-title {
    width: 75em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipse;
  }

  li {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px 0px;

    a:hover {
      color: #000 !important;
    }
  }
}