// Font paths
// -------------------------

@font-face {
  font-family: "New DS Homepage";
  src: url("../fonts/New DS Homepage.eot");
  src: url("../fonts/New DS Homepage.eot?#iefix") format("embedded-opentype"),
  url("../fonts/New DS Homepage.woff2") format("woff2"),
  url("../fonts/New DS Homepage.woff") format("woff"),
  url("../fonts/New DS Homepage.ttf") format("truetype"),
  url("../fonts/New DS Homepage.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "New DS Homepage";
    src: url("../fonts/New DS Homepage.svg#New DS Homepage") format("svg");
  }
}
