//Navigational Styles

.navigation{
    background: rgb(55,55,55);
    background: linear-gradient(180deg, rgba(55,55,55,1) 0%, rgba(0,0,0,1) 100%);
    color: #fff;

    a{
        color: #fff;
        padding: 2px 12px;
        display: inline-block;
        border-radius: 30px;

    
        &.active{

            background: #fff;
            color: #000;
        }

        &:hover, &:focus{
            text-decoration: none;
            background: rgba(#fff, .2);
        }
    }

    &__logo{
        height: 40px;

        .logo{
            height: 40px;
        }
    }  

    &__top{
        border-bottom: 1px solid #656565;
        padding: 1em;
        display: flex; 
        align-items: center;
        position: relative;
    }

    &__bottom{
        padding: 1em;
        position: relative;
    }



    &__top, &__bottom{
         ul.nav{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex; 
            align-items: center;
    
            li{
                display: inline-block;
                padding: 0 .5em;
                font-weight: 700;
                padding: 0 1em;

                @media screen and (max-width: 1150px) {
                    padding: 0 .3em;
                    font-size: .9em;
                }
            } 
        }
    } 


    .dropdown-menu{


        li{
            border-bottom: 1px solid #b8b8b8;
            width: 100%;
            
            &:last-of-type{
                border-bottom: none;
            }
        }

        a{
            color: #000;
            font-size: 1em;
            padding: .5em 0;
            display: inline-block;

           
           
        }
    }

    ul.dropdown-megamenu{

        &.show{
            display: flex;
            gap: 4px;
            padding: 8px;
            width: 630px;
            flex-wrap: wrap;
            flex-direction: row;
        }
      

        li{
            border-bottom: none;
            padding: 0;
            margin: 0;
            width: 150px;
        }

        a{
            font-size: .8em;
            color: #000;
            width: 100%;
            background:  #ECECEC;
            padding: 5px 20px;
            border-radius: 4px;
        }


        @media screen and (max-width: 992px){
            &.show{
                width: 325px;
            }

            li{
                border-bottom: none;
                padding: 0;
                margin: 0;
                width: 150px;
            }

           
    
        }

    }


    @media screen and (max-width: 992px){

        li{
            padding: 0 .3em;
        }

        a{
            padding: 2px 4px;
        }

    }
  
}


.footer-navigation{

    display: flex;
    list-style: none;
    gap: 1em;
    font-size: 1em;
    margin: 0;
    padding: 0;
    
    
    a{
        color: #fff;
        font-size: .8em;
        font-weight: 700;
    }
}


.nav-search-bar{
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    background: #fff;
    padding: 1;
    align-items: center;

    .search-input{
        border: transparent;
        //margin: 0 1em;
        box-shadow: none;
        width: 100%;

        &:focus, &:hover{
            outline: none;
            border: none;
        }
    }

    .search-icon{
        font-size: 1.4em;
        color: #656667; 
        margin: 0px 1em;

    }
}


/** Menu Top **/
.mm-menu {
    width: 100% !important;
    min-width: 140px;
    max-width: 1000px !important; 

    .mm-panel{
        background: #fff;
    }

    .mm-navbar__breadcrumbs{
        font-weight: 700;
        color: #000;
    }

    .mm-btn_close{
        background: #fff;
        &:before, &::after{
            border-color: #221f72;
            border-width: 4px;
        }
    }
}


.mm-listitem{

    margin: 1em !important;
    font-weight: 700;

    &::after{
        content: none !important;
    }

    .mm-listitem__text,  .mm-listitem__btn{
        background: #f7f7f7;
    }
}

.mm-navbars_bottom{
    .mm-navbar:last-of-type{
        background: #221f72 !important;
        color: #fff;
    }
}


.ui-autocomplete {
    max-height: 300px;
    overflow: auto;
    display: block;
    list-style: none;
    background: rgba(#fff, 0.98);
    max-width: 800px;
    z-index: 200;
    transform: translateX(-200px);
    padding: 0;

    margin: -17px 20px 0;

    padding-left: 0;
    box-shadow: 1px 2px 8px rgba(#000, 0.5); 

    .team-avatar{
        width: 80px;
        height: 80px; 
        background: #ccc;
        display: inline-block;
        margin-right: 10px;
        flex-shrink: 1;

    }


    .team-content{
        flex-grow: 1;
    }

    .icon{
        font-size: .8rem;
    }
}

.ui-menu-item a {
    display: flex;
    width: 100%;
    padding: 5px 10px;
    font-size: 17px;
    color: #171d54;
    gap: 10px;
    border-bottom: 1px solid #ccc;
    align-items: center;
}

.ui-menu-item .search-school{
    display: block;
    font-size: 20px;
    font-weight: 500;
}

.ui-menu-item .search-school .mascot{
    opacity: .5;
    font-size: 1rem;
    display: inline-block;
    margin-left: .5em;
}

.ui-menu-item .subvalue {
    /* color: #2b316f; */
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
    opacity: .4;
}

ul.ui-autocomplete li.ui-menu-item a.ui-state-hover, ul.ui-autocomplete li.ui-menu-item a:hover {
    background: #1f2238 !important;
    color: #fff;
}

.ui-widget-content .ui-state-active {
    border: none;
    background: none;
    color: inherit;
}



@media screen and (max-width: 1000px) {
    .mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
        transform: translate3d(100vw,0,0) !important;
      -webkit-transform: translate(100vw, 0) !important;
     
    } 
}


