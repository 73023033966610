h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-family: "acier-bat-solid", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
}


p,ul,span,li,dd,dt,div{
    font-family: Arial, Helvetica, sans-serif;
}