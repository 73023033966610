.flex{
    &-grow{
        flex-grow: 1;    
    } 

    &-align-center{
        align-items: baseline;
    }
}


.badge{

    &-sm{

        font-size: .8em;
        padding: 4px 8px;

        *{
            margin-bottom: 0;
        }
    }

    &-default{
        background: #e5e5e5;
        color: #4c4c4c;
    }
}


@each $name, $color in $theme-colors{
  .color-#{$name}{
      color: $color;
  }
}