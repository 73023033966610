.ad-space{
    border-radius: 1em;
    background: #F5F5FB;
    padding: 1em;

}


@media screen and (max-width: 800px){
    .ad-space{
        padding: 0;
    }    
}