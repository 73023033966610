.btn{

    border-radius: 8px;

    &-white{
        background: #fff;
        color: #000;
        border: 1px solid #fff;

        &:hover, &:focus{
            background: #000;
            color: #fff;
        }

        &.btn-outline{
            border: 2px solid #fff;
            color: #fff;
            background: transparent;

            &:hover, &:focus{
                background: #fff;
                color: #000;
            }
        }
    }

}