// Icons
// -------------------------

.dshome-four-seasons:before,
.dshome-summer:before,
.dshome-fall:before,
.dshome-winter:before,
.dshome-flower:before,
[data-icons8]:before {
  @include icons8-font;
}

[data-icons8]:before {
  content: attr(data-icons8);
}

.dshome-four-seasons:before {
  content: $icons8-var-four-seasons;
}
.dshome-summer:before {
  content: $icons8-var-summer;
}
.dshome-fall:before {
  content: $icons8-var-fall;
}
.dshome-winter:before {
  content: $icons8-var-winter;
}
.dshome-flower:before {
  content: $icons8-var-flower;
}


/*******************
*
* Flag Icons
*
*******************/

.icon-flag{
  background: #000;
  height: 15px; 
  width: 15px;
  border-radius: 100%;
  display: inline-block;
  margin-left: -14px;
  vertical-align: text-top;
  background-position: center center;
  background-size: 20px auto;
}

.national{
  background-image: url('../images/icons/american-flag.svg');
}

.district-of-columbia{
  background-image: url('../images/flags/district-of-columbia.png');
}

.massachusetts{
  background-image: url('../images/flags/massachusetts.png');
}

.new-jersey{
  background-image: url('../images/flags/new-jersey.png');
}

.rhode-island{
  background-image: url('../images/flags/rhode-island.png');
}

.alabama{
  background-image: url('../images/flags/alabama.png');
}

.florida{
  background-image: url('../images/flags/alabama.png');
}


.michigan{
  background-image: url('../images/flags/michigan.png');
}


.new-mexico{
  background-image: url('../images/flags/new-mexico.png');
}


.south-carolina{
  background-image: url('../images/flags/south-carolina.png');
}


.alaska{
  background-image: url('../images/flags/alaska.png');
}


.georgia{
  background-image: url('../images/flags/georgia.png');
}


.minnesota{
  background-image: url('../images/flags/minnesota.png');
}


.new-york{
  background-image: url('../images/flags/new-york.png');
}


.south-dakota{
  background-image: url('../images/flags/south-dakota.png');
}


.arizona{
  background-image: url('../images/flags/arizona.png');
}


.hawaii{
  background-image: url('../images/flags/hawaii.png');
}


.mississippi{
  background-image: url('../images/flags/mississippi.png');
}


.north-carolina{
  background-image: url('../images/flags/north-carolina.png');
}



.tennessee{
  background-image: url('../images/flags/tennessee.png');
}


.arkansas{
  background-image: url('../images/flags/arkansas.png');
}


.idaho{
  background-image: url('../images/flags/idaho.png');
}


.missouri{
  background-image: url('../images/flags/missouri.png');
}

.north-dakota{
  background-image: url('../images/flags/north-dakota.png');
}

.texas{
  background-image: url('../images/flags/texas.png');
}

.california{
  background-image: url('../images/flags/california.png');
}

.illinois{
  background-image: url('../images/flags/illinois.png');
}

.montana{
  background-image: url('../images/flags/montana.png');
}

.ohio{
  background-image: url('../images/flags/ohio.png');
}

.utah{
  background-image: url('../images/flags/utah.png');
}

.colorado{
  background-image: url('../images/flags/colorado.png');
}

.indiana{
  background-image: url('../images/flags/indiana.png');
}

.nebraska{
  background-image: url('../images/flags/nebraska.png');
}

.oklahoma{
  background-image: url('../images/flags/oklahoma.png');
}

.vermont{
  background-image: url('../images/flags/vermont.png');
}

.connecticut{
  background-image: url('../images/flags/connecticut.png');
}

.iowa{
  background-image: url('../images/flags/iowa.png');
}

.nevada{
  background-image: url('../images/flags/nevada.png');
}

.oregon{
  background-image: url('../images/flags/oregon.png');
}

.virginia{
  background-image: url('../images/flags/virginia.png');
}

.kansas{
  background-image: url('../images/flags/kansas.png');
}

.new-hampshire{
  background-image: url('../images/flags/new-hampshire.png');
}

.pennsylvania{
  background-image: url('../images/flags/pennsylvania.png');
}

.washington{
  background-image: url('../images/flags/washington.png');
}

.canada{
  background-image: url('../images/flags/canada.png');
}

.maryland{
  background-image: url('../images/flags/maryland.png');
}

.maine{
  background-image: url('../images/flags/maine.png');
}








