// DigitalSport Homepage Base Styles

html,body{
    background: #F2F1EF; 
}

.main-content{
    background: #fff;
    border-radius: 28px;
    overflow: hidden;
}

.header{
    &--main{
        min-height: 400px;
        display: inline-block;
        width: 100%;
        position: relative;
        padding: 1em;
        display: flex;
        flex-direction: column;
        border-radius: 28px;
        overflow: hidden;

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.8625117283241421) 100%); 
        }
    }

    &__top{
        flex-grow: 1;
        position: relative;
        z-index: 2;
    }

    &__description{
        flex-shrink: 1;
        color: #fff;
        position: relative;
        z-index: 2;

        p{
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        
    }
}

   


.tile{

    border-radius: 28px;
    border: 1px solid transparent;
    transition: border .2s ease-in;

    &.active{
        border: 2px solid #221F72;

        &:focus, &:hover{
            border: 2px solid #221F72;
        }
    
        .progress-badge{
            display: initial;
        }
    }

    .progress-badge{
        display: none;
        font-size: .8em;
        padding: 4px 8px;
    }

   
        min-width: 100px;
        background: #fff;
        box-shadow:  0 3px 6px rgba(0,0,0,.16);

        h3{
            font-size: 1.3em;
        }

        p{
            font-size: .8em;
        }


        @media screen and  (max-width: 800px){
            display: flex;
            margin-top: -0.8rem;
            box-shadow: none;
            border-radius: 0;
            border-bottom: 1px solid #ccc;
            align-items: center;

            .tile{

                &__header{
                    height: 100px;
                    min-width: 100px;
                    max-width: 100px;
                    flex-grow: 1;
                }
                &__content{
                    min-height: 0;
                    max-height: 100%;
                    //Vertical overflow
                    h3{
                        font-size: 1em;
                    }
                    
                    p{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        margin-bottom: .3em;
                
                    }
                   
                }
            }
        }
    

    &--score{
        h2,h3,h4,h5,h6{
            font-family: 'Century Gothic Pro', Helvetica, sans-serif;
            font-weight: 700;
        }

        h3{
            font-size: .8em;
        }

        p,div{
            font-size: 1em;
            line-height: 1em;
        }


        @media screen and  (max-width: 800px){
            border-radius: 28px;
            border: 1px solid transparent;
            transition: border .2s ease-in;
            margin: .5em 0;
            min-width: 100px;
            background: #fff;
            box-shadow:  0 3px 6px rgba(0,0,0,.16);

        }
    }

    &__header{
        height: 150px;
        width: 100%;
        border-radius: 28px;
        display: inline-block;
        background: #ccc;
        background-position: center center;
    }

    &__content{
        min-height: 150px;
        max-height: 150px;
        //Vertical overflow

        h3{
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        p{
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
    
        }
       
    }

    .badge-container{
        height: 28px;
    }

    &__footer{
        margin-top: 1em;

        .small{
            font-size: .55em;
            font-weight: 700;
        }

        a{
            color: #000;
        }
    }

    &:hover, &:focus{
        border: 1px solid #221F72;
    }


   

}


.score-row{
    display: flex;
    width: 100%;
    gap: 1em;
    padding-top: 1em;
    padding-bottom: 1em;


    &__avatar{
        width: 25px;
        height: 25px;
        font-weight: 700;
        background: #ccc;
        border-radius: 100%;
        text-align: center;
    }

    &__school-name{
        flex-grow: 1;
    }

    &__score{
        font-weight: 700;
    }

    &--loss{
        opacity: .3;
    }

}



.datebar{
    display: flex;
    flex-wrap: nowrap;
    margin: .5em 0;
    text-align: center;
    justify-content: center;
  
}

.datebar__date{
    padding: .5em .3em;
    text-align: center;
    color: #131313;
    background: #e6e6e6;
    border: 2px solid #e6e6e6;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.datebar__date:hover{
    
    color: #131313;

   
}

.datebar__date .date-month-container{
   
    padding: .5em 1em;
    border-radius: 8px;
}


.datebar__date:hover .date-month-container{
    background: #f1f1f1;

}

.datebar__date.active .date-month-container{
    background: #f1f1f1;

}

.datebar__date.today .date-month-container{
    border: 1px solid #7270b8;

}



.datebar__date {
    position: relative;
}

.datebar__date p{
    margin-bottom: 0px;
    padding-bottom: 0px; 
    margin-top: 1px;
    padding-top: 1px; 
}

.datebar .datebar__date:first-of-type{
    border-radius: 8px 0 0 8px;
}


.datebar__date:last-of-type{
    border-radius: 0px 8px 8px 0;
}

.datebar__date .month{
    font-size: .8em;
    font-family: Arial, Helvetica, sans-serif;
}

.datebar__date .date{
    font-size: 1.1em;
    font-family: acier-bat-solid, sans-serif;
    font-weight: 500;
}


.datebar .date__toggle{

}

.datebar .date__selection{
    visibility: hidden;
    position: absolute;
}



footer{
    background: #262626;
    padding: 2em;

    .terms-of-use{
        color: #C7C7C7;
    } 
}


/** Ads **/
.sky-scraper-ad{
    top: 0;
    position: sticky;
}


#search-bar{
    flex-grow: 1;
    position: relative;
}

